const mailingBuckets = ["COMPLAINT FORM FILLED", "DRAFT MAIL GENERATED", "EXPERT APPROVED DRAFT MAIL", "Company", "IGMS", "COMPANY/IGMS", "ESCALATION PENDING", "ESCALATION MAIL GENERATED",
"ESCALATION APPROVED", "COMPANY ESCALATED", "IGMS ESCALATED", "COMPANY/IGMS ESCALATED", "NO RESPONSE RECEIVED", "REMINDER MAIL SENT", "COMPANY REQUIREMENT RECEIVED", "COMPANY REQUIREMENT SENT", "COMPANY",
"Ombudsman Pending", "OMBUDSMAN PENDING", "EXPERT MAIL DRAFTED", "COMPANY ESCALATION DONE","COMPLAINT FORM SENT"];
const legalBuckets =  ["Legal", "LEGAL", "LEGAL CONTRACT PENDING", "LEGAL CONTRACT SIGNED", "LEGAL QUERY RAISED", "LEGAL QUERY ANSWERED", "LEGAL DRAFT FOR APPROVAL", "COURT FILING POINTS", "COURT FILING PENDING BUCKET", "CASE FILING DONE", "LEGAL RECOVERY FROM CUSTOMER", "LEGAL NOTICE RECEIVED","LEGAL NOTICE SENT TO CUSTOMER","LEGAL NOTICE SENT TO COMPANY",
"LEGAL RECOVERY FROM COMPANY", "COURT ISSUED SUMMON", "COMPANY REPLY AWAITED", "REJOINDER NEED TO BE FILED", "AFFIDAVIT NEED TO BE FILED", "COMPLAINANT/PLAINTIFF EVIDENCE", "DEFENDANT/RESPONDENT EVIDENCE", "SETTLEMENT PROPOSAL SUBMISSION PENDING", "SETTLEMENT PROPOSAL SUBMITTED", "ARGUMENTS", "FINAL ARGUMENTS", "ORDER AWAITED","Court Case Won"];
const omdBuckets = [ "Ombudsman without Legal", "OMBUDSMAN REQUIREMENT PENDING", "OMBUDSMAN REQUIREMENT PUSHED", "OMBUDSMAN REQUIREMENT SENT", "FORM 6A RECEIVED", "FORM 6A PUSHED", "FORM 6A SENT",
"HEARING DATE RECEIVED", "HEARING POSTPONED", "HEARING DONE", "AWARD ACCEPTED", "AWARD REJECTED", "OMBUDSMAN WITH LEGAL PENDING", "OMBUDSMAN WITH LEGAL SENT","BRIEFING PENDING","BRIEFING COMPLETED", "OMBUDSMAN WITHOUT LEGAL", "Ombudsman with Legal", "OMBUDSMAN WITH LEGAL"];


// const omdBucketUnique = ["Ombudsman Pending", "OMBUDSMAN REQUIREMENT PENDING", "OMBUDSMAN REQUIREMENT PUSHED", "COMPLAINT FORM SENT", "OMBUDSMAN REQUIREMENT SENT", "Ombudsman without Legal",
// "FORM 6A RECEIVED", "FORM 6A PUSHED", "FORM 6A SENT", "HEARING DATE RECEIVED", "HEARING POSTPONED", "HEARING DONE", "AWARD ACCEPTED", "AWARD REJECTED"];

const getCustomerCount = (arr) => {
    let customerCount;
    if (arr && arr.length) {
      const userIdArray = arr.filter((item, i, ar) => ar.indexOf(item) === i);
      customerCount = userIdArray.length;
    } else {
      customerCount = '-';
    }
    return customerCount.toString();
  }

export const getCountB2C = (caseMonthAll) => {
    let casesIvr = 0;
    let casesDirect = 0;
    let casesWA = 0;
    let casesCB = 0;
    let casesWG = 0;
    let casesOrganic = 0;
    let casesInsa = 0;
    let casesCompany = 0;
    let casesOmbudsman = 0;
    let casesLegal = 0;

    let claimIvr = 0;
    let claimDirect = 0;
    let claimWA = 0;
    let claimCB = 0;
    let claimWG = 0;
    let claimOrganic = 0;
    let claimInsa = 0;
    let claimCompany = 0;
    let claimOmbudsman = 0;
    let claimLegal = 0;

    let claimTotalIvr = 0;
    let claimTotalDirect = 0;
    let claimTotalWA = 0;
    let claimTotalCB = 0;
    let claimTotalWG = 0;
    let claimTotalOrganic = 0;
    let claimTotalInsa = 0;
    let claimTotalCompany = 0;
    let claimTotalOmbudsman = 0;
    let claimTotalLegal = 0;

    let claimPendingIvr = 0;
    let claimPendingDirect = 0;
    let claimPendingWA = 0;
    let claimPendingCB = 0;
    let claimPendingWG = 0;
    let claimPendingOrganic = 0;
    let claimPendingInsa = 0;
    let claimPendingCompany = 0;
    let claimPendingOmbudsman = 0;
    let claimPendingLegal = 0;

    let customerIvr = [];
    let customerDirect = [];
    let customerWA = [];
    let customerCB = [];
    let customerWG = [];
    let customerOrganic = [];
    let customerInsa = [];
    let customerCompany = [];
    let customerOmbudsman = [];
    let customerLegal = [];

    if (caseMonthAll && caseMonthAll.length > 0) {
      for (let j = 0; j < caseMonthAll.length; j++) {
        const complaintSingle = caseMonthAll[j];
        if (caseMonthAll[j].leadId) {
          const lead = caseMonthAll[j].leadId;
          if (
            lead.src &&
            (lead.src === 'IVR' || lead.src === 'MissedCall') &&
            lead.cpn !== 'Chatbot'
          ) {
            casesIvr = casesIvr + 1;
            claimIvr =
              claimIvr +
              (complaintSingle.claimAmount
                ? Number(complaintSingle.claimAmount)
                : 0);
            claimTotalIvr =
              claimTotalIvr +
              (complaintSingle.actualRefundAmount
                ? Number(complaintSingle.actualRefundAmount)
                : 0);
            claimPendingIvr =
            claimPendingIvr +
            (complaintSingle.pendingClaimAmount
              ? Number(complaintSingle.pendingClaimAmount)
              : 0);
            customerIvr.push(complaintSingle.userId);
          } else if (lead.src && lead.src === 'Direct') {
            casesDirect = casesDirect + 1;
            claimDirect =
              claimDirect +
              (complaintSingle.claimAmount
                ? Number(complaintSingle.claimAmount)
                : 0);
            claimTotalDirect =
              claimTotalDirect +
              (complaintSingle.actualRefundAmount
                ? Number(complaintSingle.actualRefundAmount)
                : 0);
            claimPendingDirect =
              claimPendingDirect +
              (complaintSingle.pendingClaimAmount
                ? Number(complaintSingle.pendingClaimAmount)
                : 0);
            customerDirect.push(complaintSingle.userId);
          } else if (
            lead.src &&
            (lead.src === 'Whatsapp' || lead.src === 'WhatsApp')
          ) {
            casesWA = casesWA + 1;
            claimWA =
              claimWA +
              (complaintSingle.claimAmount
                ? Number(complaintSingle.claimAmount)
                : 0);
            claimTotalWA =
              claimTotalWA +
              (complaintSingle.actualRefundAmount
                ? Number(complaintSingle.actualRefundAmount)
                : 0);
            claimPendingWA =
              claimPendingWA +
              (complaintSingle.pendingClaimAmount
                ? Number(complaintSingle.pendingClaimAmount)
                : 0);
            customerWA.push(complaintSingle.userId);
          } else if (lead.cpn && lead.cpn === 'Chatbot') {
            casesCB = casesCB + 1;
            claimCB =
              claimCB +
              (complaintSingle.claimAmount
                ? Number(complaintSingle.claimAmount)
                : 0);
            claimTotalCB =
              claimTotalCB +
              (complaintSingle.actualRefundAmount
                ? Number(complaintSingle.actualRefundAmount)
                : 0);
            claimPendingCB =
              claimPendingCB +
              (complaintSingle.pendingClaimAmount
                ? Number(complaintSingle.pendingClaimAmount)
                : 0);
            customerCB.push(complaintSingle.userId);
          } else if (lead.med && lead.med === 'wgcpc') {
            casesWG = casesWG + 1;
            claimWG =
              claimWG +
              (complaintSingle.claimAmount
                ? Number(complaintSingle.claimAmount)
                : 0);
            claimTotalWG =
              claimTotalWG +
              (complaintSingle.actualRefundAmount
                ? Number(complaintSingle.actualRefundAmount)
                : 0);
            claimPendingWG =
                claimPendingWG +
                (complaintSingle.pendingClaimAmount
                  ? Number(complaintSingle.pendingClaimAmount)
                  : 0);
            customerWG.push(complaintSingle.userId);
          } else if (
            lead.med &&
            (lead.med === 'organic' || lead.med === 'Organic')
          ) {
            casesOrganic = casesOrganic + 1;
            claimOrganic =
              claimOrganic +
              (complaintSingle.claimAmount
                ? Number(complaintSingle.claimAmount)
                : 0);
            claimTotalOrganic =
              claimTotalOrganic +
              (complaintSingle.actualRefundAmount
                ? Number(complaintSingle.actualRefundAmount)
                : 0);
            claimPendingOrganic =
                claimPendingOrganic +
                (complaintSingle.pendingClaimAmount
                  ? Number(complaintSingle.pendingClaimAmount)
                  : 0);
            customerOrganic.push(complaintSingle.userId);
          } else {
            casesInsa = casesInsa + 1;
            claimInsa =
              claimInsa +
              (complaintSingle.claimAmount
                ? Number(complaintSingle.claimAmount)
                : 0);
            claimTotalInsa =
              claimTotalInsa +
              (complaintSingle.actualRefundAmount
                ? Number(complaintSingle.actualRefundAmount)
                : 0);
            claimPendingInsa =
              claimPendingInsa +
              (complaintSingle.pendingClaimAmount
                ? Number(complaintSingle.pendingClaimAmount)
                : 0);
            customerInsa.push(complaintSingle.userId);
          }
        }
        let resolvedBeforeStatus;
        if (caseMonthAll[j].statusHistory && caseMonthAll[j].statusHistory.length > 0) {
            for (let m = caseMonthAll[j].statusHistory.length - 1; m > 0; m--) {
                if (caseMonthAll[j].statusHistory[m]["currStatus"] === "Resolved") {
                    resolvedBeforeStatus = caseMonthAll[j].statusHistory[m - 1] ? caseMonthAll[j].statusHistory[m - 1]["currStatus"] : "--";
                    break;
                }
            }
        }
        if ((mailingBuckets).includes(resolvedBeforeStatus)) {
          casesCompany = casesCompany + 1;
          claimCompany =
            claimCompany +
            (complaintSingle.claimAmount
              ? Number(complaintSingle.claimAmount)
              : 0);
          claimTotalCompany =
            claimTotalCompany +
            (complaintSingle.actualRefundAmount
              ? Number(complaintSingle.actualRefundAmount)
              : 0);
          claimPendingCompany =
            claimPendingCompany +
            (complaintSingle.pendingClaimAmount
              ? Number(complaintSingle.pendingClaimAmount)
              : 0);
            customerCompany.push(complaintSingle.userId);
        }
        if ((omdBuckets).includes(resolvedBeforeStatus)) {
          casesOmbudsman = casesOmbudsman + 1;
          claimOmbudsman =
            claimOmbudsman +
            (complaintSingle.claimAmount
              ? Number(complaintSingle.claimAmount)
              : 0);
          claimTotalOmbudsman =
            claimTotalOmbudsman +
            (complaintSingle.actualRefundAmount
              ? Number(complaintSingle.actualRefundAmount)
              : 0);
          claimPendingOmbudsman =
            claimPendingOmbudsman +
            (complaintSingle.pendingClaimAmount
              ? Number(complaintSingle.pendingClaimAmount)
              : 0);
            customerOmbudsman.push(complaintSingle.userId);
        }
        if ((legalBuckets).includes(resolvedBeforeStatus)) {
          casesLegal = casesLegal + 1;
          claimLegal =
            claimLegal +
            (complaintSingle.claimAmount
              ? Number(complaintSingle.claimAmount)
              : 0);
          claimTotalLegal =
              claimTotalLegal +
              (complaintSingle.actualRefundAmount
                ? Number(complaintSingle.actualRefundAmount)
                : 0);
          claimPendingLegal =
              claimPendingLegal +
              (complaintSingle.pendingClaimAmount
                ? Number(complaintSingle.pendingClaimAmount)
                : 0);
            customerLegal.push(complaintSingle.userId);
        }
      }
    }
    let obj = {
      casesIvr: casesIvr ? casesIvr.toString() : '-',
      casesDirect: casesDirect ? casesDirect.toString() : '-',
      casesWA: casesWA ? casesWA.toString() : '-',
      casesCB: casesCB ? casesCB.toString() : '-',
      casesWG: casesWG ? casesWG.toString() : '-',
      casesOrganic: casesOrganic ? casesOrganic.toString() : '-',
      casesInsa: casesInsa ? casesInsa.toString() : '-',
      casesCompany: casesCompany ? casesCompany.toString() : '-',
      casesOmbudsman: casesOmbudsman ? casesOmbudsman.toString() : '-',
      casesLegal: casesLegal ? casesLegal.toString() : '-',
      claimIvr: claimIvr ? (claimIvr.toFixed(2)).toString() : '-',
      claimDirect: claimDirect ? (claimDirect.toFixed(2)).toString() : '-',
      claimWA: claimWA ? (claimWA.toFixed(2)).toString() : '-',
      claimCB: claimCB ? (claimCB.toFixed(2)).toString() : '-',
      claimWG: claimWG ? (claimWG.toFixed(2)).toString() : '-',
      claimOrganic: claimOrganic ? (claimOrganic.toFixed(2)).toString() : '-',
      claimInsa: claimInsa ? (claimInsa.toFixed(2)).toString() : '-',
      claimCompany: claimCompany ? (claimCompany.toFixed(2)).toString() : '-',
      claimOmbudsman: claimOmbudsman ? (claimOmbudsman.toFixed(2)).toString() : '-',
      claimLegal: claimLegal ? (claimLegal.toFixed(2)).toString() : '-',

      claimTotalIvr: claimTotalIvr ? (claimTotalIvr.toFixed(2)).toString() : '-',
      claimTotalDirect: claimTotalDirect ? (claimTotalDirect.toFixed(2)).toString() : '-',
      claimTotalWA: claimTotalWA ? (claimTotalWA.toFixed(2)).toString() : '-',
      claimTotalCB: claimTotalCB ? (claimTotalCB.toFixed(2)).toString() : '-',
      claimTotalWG: claimTotalWG ? (claimTotalWG.toFixed(2)).toString() : '-',
      claimTotalOrganic: claimTotalOrganic ? (claimTotalOrganic.toFixed(2)).toString() : '-',
      claimTotalInsa: claimTotalInsa ? (claimTotalInsa.toFixed(2)).toString() : '-',
      claimTotalCompany: claimTotalCompany ? (claimTotalCompany.toFixed(2)).toString() : '-',
      claimTotalOmbudsman: claimTotalOmbudsman ? (claimTotalOmbudsman.toFixed(2)).toString() : '-',
      claimTotalLegal: claimTotalLegal ? (claimTotalLegal.toFixed(2)).toString() : '-',

      claimPendingIvr: claimPendingIvr ? (claimPendingIvr.toFixed(2)).toString() : '-',
      claimPendingDirect: claimPendingDirect ? (claimPendingDirect.toFixed(2)).toString() : '-',
      claimPendingWA: claimPendingWA ? (claimPendingWA.toFixed(2)).toString() : '-',
      claimPendingCB: claimPendingCB ? (claimPendingCB.toFixed(2)).toString() : '-',
      claimPendingWG: claimPendingWG ? (claimPendingWG.toFixed(2)).toString() : '-',
      claimPendingOrganic: claimPendingOrganic ? (claimPendingOrganic.toFixed(2)).toString() : '-',
      claimPendingInsa: claimPendingInsa ? (claimPendingInsa.toFixed(2)).toString() : '-',
      claimPendingCompany: claimPendingCompany ? (claimPendingCompany.toFixed(2)).toString() : '-',
      claimPendingOmbudsman: claimPendingOmbudsman ? (claimPendingOmbudsman.toFixed(2)).toString() : '-',
      claimPendingLegal: claimPendingLegal ? (claimPendingLegal.toFixed(2)).toString() : '-',
      
      customerIvr: customerIvr ? getCustomerCount(customerIvr) : '-',
      customerDirect: customerDirect
        ? getCustomerCount(customerDirect)
        : '-',
      customerWA: customerWA ? getCustomerCount(customerWA) : '-',
      customerCB: customerCB ? getCustomerCount(customerCB) : '-',
      customerWG: customerWG ? getCustomerCount(customerWG) : '-',
      customerOrganic: customerOrganic
        ? getCustomerCount(customerOrganic)
        : '-',
      customerInsa: customerInsa ? getCustomerCount(customerInsa) : '-',
      customerCompany: customerCompany ? getCustomerCount(customerCompany) : '-',
      customerOmbudsman: customerOmbudsman
        ? getCustomerCount(customerOmbudsman)
        : '-',
      customerLegal: customerLegal ? getCustomerCount(customerLegal) : '-',
    };
    return obj;
  }


  
  //not in use (currently)
  export const getCountPartner = (caseMonthAll) => {
    let casesCompany = 0;
    let casesOmbudsman = 0;
    let casesLegal = 0;

    let claimCompany = 0;
    let claimOmbudsman = 0;
    let claimLegal = 0;

    let claimTotalCompany = 0;
    let claimTotalOmbudsman = 0;
    let claimTotalLegal = 0;

    let claimPendingCompany = 0;
    let claimPendingOmbudsman = 0;
    let claimPendingLegal = 0;

    let customerCompany = [];
    let customerOmbudsman = [];
    let customerLegal = [];

    if (caseMonthAll && caseMonthAll.length > 0) {
      for (let j = 0; j < caseMonthAll.length; j++) {
        const complaintSingle = caseMonthAll[j];
        let resolvedBeforeStatus;
        if (caseMonthAll[j].statusHistory && (caseMonthAll[j].statusHistory).length > 0) {
          for (let m = 0; m < (caseMonthAll[j].statusHistory).length; m++) {
            if (((caseMonthAll[j].statusHistory)[m])['status'] === 'RESOLVED') {
              resolvedBeforeStatus = (caseMonthAll[j].statusHistory)[m - 1] ? ((caseMonthAll[j].statusHistory)[m - 1])['status'] : '--';
              break;
            }
          }
        }
        if ((mailingBuckets).includes(resolvedBeforeStatus)) {
          casesCompany = casesCompany + 1;
          claimCompany =
            claimCompany +
            (complaintSingle.claimAmount
              ? Number(complaintSingle.claimAmount)
              : 0);
          claimTotalCompany =
            claimTotalCompany +
              (complaintSingle.approvedClaimAmount
                ? Number(complaintSingle.approvedClaimAmount)
                : 0);
          claimPendingCompany =
            claimPendingCompany +
              (complaintSingle.pendingClaimAmount
                ? Number(complaintSingle.pendingClaimAmount)
                : 0);
            customerCompany.push(complaintSingle.userId);
        }
        if ((omdBuckets).includes(resolvedBeforeStatus)) {
          casesOmbudsman = casesOmbudsman + 1;
          claimOmbudsman =
            claimOmbudsman +
            (complaintSingle.claimAmount
              ? Number(complaintSingle.claimAmount)
              : 0);
          claimTotalOmbudsman =
            claimTotalOmbudsman +
              (complaintSingle.approvedClaimAmount
                ? Number(complaintSingle.approvedClaimAmount)
                : 0);
          claimPendingOmbudsman =
            claimPendingOmbudsman +
              (complaintSingle.pendingClaimAmount
                ? Number(complaintSingle.pendingClaimAmount)
                : 0);
            customerOmbudsman.push(complaintSingle.userId);
        }
        if ((legalBuckets).includes(resolvedBeforeStatus)) {
          casesLegal = casesLegal + 1;
          claimLegal =
            claimLegal +
            (complaintSingle.claimAmount
              ? Number(complaintSingle.claimAmount)
              : 0);
          claimTotalLegal =
            claimTotalLegal +
              (complaintSingle.approvedClaimAmount
                ? Number(complaintSingle.approvedClaimAmount)
                : 0);
          claimPendingLegal =
            claimPendingLegal +
              (complaintSingle.pendingClaimAmount
                ? Number(complaintSingle.pendingClaimAmount)
                : 0);
            customerLegal.push(complaintSingle.userId);
        }
      }
    }
    let obj = {
      casesCompany: casesCompany ? casesCompany.toString() : '-',
      casesOmbudsman: casesOmbudsman ? casesOmbudsman.toString() : '-',
      casesLegal: casesLegal ? casesLegal.toString() : '-',

      claimCompany: claimCompany ? (claimCompany.toFixed(2)).toString() : '-',
      claimOmbudsman: claimOmbudsman ? (claimOmbudsman.toFixed(2)).toString() : '-',
      claimLegal: claimLegal ? (claimLegal.toFixed(2)).toString() : '-',

      claimTotalCompany: claimTotalCompany ? (claimTotalCompany.toFixed(2)).toString() : '-',
      claimTotalOmbudsman: claimTotalOmbudsman ? (claimTotalOmbudsman.toFixed(2)).toString() : '-',
      claimTotalLegal: claimTotalLegal ? (claimTotalLegal.toFixed(2)).toString() : '-',

      claimPendingCompany: claimPendingCompany ? (claimPendingCompany.toFixed(2)).toString() : '-',
      claimPendingOmbudsman: claimPendingOmbudsman ? (claimPendingOmbudsman.toFixed(2)).toString() : '-',
      claimPendingLegal: claimPendingLegal ? (claimPendingLegal.toFixed(2)).toString() : '-',

      customerCompany: customerCompany ? getCustomerCount(customerCompany) : '-',
      customerOmbudsman: customerOmbudsman
        ? getCustomerCount(customerOmbudsman)
        : '-',
      customerLegal: customerLegal ? getCustomerCount(customerLegal) : '-',
    };
    return obj;
  }