export const COMPLAINT_GET_WAIVEOFF = "COMPLAINT_GET_WAIVEOFF"
export const COMPLAINT_GET_WAIVEOFF_SUCCESS = "COMPLAINT_GET_WAIVEOFF_SUCCESS"
export const COMPLAINT_GET_WAIVEOFF_FAILED = "COMPLAINT_GET_WAIVEOFF_FAILED"

export const COMPLAINT_FEE_OPERATION = "COMPLAINT_FEE_OPERATION"
export const COMPLAINT_FEE_OPERATION_SUCCESS = "COMPLAINT_FEE_OPERATION_SUCCESS"
export const COMPLAINT_FEE_OPERATION_FAILED = "COMPLAINT_FEE_OPERATION_FAILED"

export const COMPLAINT_SECOND_ADD_FINAL_AMOUNT = "COMPLAINT_SECOND_ADD_FINAL_AMOUNT"
export const COMPLAINT_SECOND_ADD_FINAL_AMOUNT_SUCCESS = "COMPLAINT_SECOND_ADD_FINAL_AMOUNT_SUCCESS"
export const COMPLAINT_SECOND_ADD_FINAL_AMOUNT_FAILED = "COMPLAINT_SECOND_ADD_FINAL_AMOUNT_FAILED"

export const COMPLAINT_FINAL_PAYMENT = "COMPLAINT_FINAL_PAYMENT"
export const COMPLAINT_FINAL_PAYMENT_SUCCESS = "COMPLAINT_FINAL_PAYMENT_SUCCESS"
export const COMPLAINT_FINAL_PAYMENT_FAILED = "COMPLAINT_FINAL_PAYMENT_FAILED"

export const COMPLAINT_CLAIM_OPERATION = "COMPLAINT_CLAIM_OPERATION"
export const COMPLAINT_CLAIM_OPERATION_SUCCESS = "COMPLAINT_CLAIM_OPERATION_SUCCESS"
export const COMPLAINT_CLAIM_OPERATION_FAILED = "COMPLAINT_CLAIM_OPERATION_FAILED"

export const COMPLAINT_CALL_LOG = "COMPLAINT_CALL_LOG"
export const COMPLAINT_SERVETEL_CALL_LOG = "COMPLAINT_SERVETEL_CALL_LOG"
export const COMPLAINT_CALL_LOG_SUCCESS = "COMPLAINT_CALL_LOG_SUCCESS"
export const COMPLAINT_CALL_LOG_FAILED = "COMPLAINT_CALL_LOG_FAILED"

export const COMPLAINT_GET_COMMENTS = "COMPLAINT_GET_COMMENTS"
export const COMPLAINT_GET_COMMENTS_SUCCESS = "COMPLAINT_GET_COMMENTS_SUCCESS"
export const COMPLAINT_GET_COMMENTS_FAILED = "COMPLAINT_GET_COMMENTS_FAILED"

export const COMPLAINT_ADD_COMMENT = "COMPLAINT_ADD_COMMENT"
export const COMPLAINT_ADD_COMMENT_SUCCESS = "COMPLAINT_ADD_COMMENT_SUCCESS"
export const COMPLAINT_ADD_COMMENT_FAILED = "COMPLAINT_ADD_COMMENT_FAILED"

export const COMPLAINT_UPDATE_COMMENT = "COMPLAINT_UPDATE_COMMENT"
export const COMPLAINT_UPDATE_COMMENT_SUCCESS = "COMPLAINT_UPDATE_COMMENT_SUCCESS"
export const COMPLAINT_UPDATE_COMMENT_FAILED = "COMPLAINT_UPDATE_COMMENT_FAILED"

export const COMPLAINANT_GET_USER_BASED_DATA = "COMPLAINANT_GET_USER_BASED_DATA"
export const COMPLAINANT_GET_USER_BASED_DATA_SUCCESS = "COMPLAINANT_GET_USER_BASED_DATA_SUCCESS"
export const COMPLAINANT_GET_USER_BASED_DATA_FAILED = "COMPLAINANT_GET_USER_BASED_DATA_FAILED"

export const COMPLAINANT_GET_STATUS_BUCKET = "COMPLAINANT_GET_STATUS_BUCKET"
export const COMPLAINANT_GET_STATUS_BUCKET_SUCCESS = "COMPLAINANT_GET_STATUS_BUCKET_SUCCESS"
export const COMPLAINANT_GET_STATUS_BUCKET_FAILED = "COMPLAINANT_GET_STATUS_BUCKET_FAILED"

export const COMPLAINANT_UPDATE_COMPLAINT_STATUS = "COMPLAINANT_UPDATE_COMPLAINT_STATUS"
export const COMPLAINANT_UPDATE_COMPLAINT_STATUS_SUCCESS = "COMPLAINANT_UPDATE_COMPLAINT_STATUS_SUCCESS"
export const COMPLAINANT_UPDATE_COMPLAINT_STATUS_FAILED = "COMPLAINANT_UPDATE_COMPLAINT_STATUS_FAILED"

export const COMPLAINANT_USER_DETAIL_HANDLE_COMPLAINT = "COMPLAINANT_USER_DETAIL_HANDLE_COMPLAINT"
export const COMPLAINANT_USER_DETAIL_HANDLE_COMPLAINT_SUCCESS = "COMPLAINANT_USER_DETAIL_HANDLE_COMPLAINT_SUCCESS"
export const COMPLAINANT_USER_DETAIL_HANDLE_COMPLAINT_FAILED = "COMPLAINANT_USER_DETAIL_HANDLE_COMPLAINT_FAILED"

export const COMPLAINANT_UPDATE_INFORMATION = "COMPLAINANT_UPDATE_INFORMATION"
export const COMPLAINANT_UPDATE_INFORMATION_SUCCESS = "COMPLAINANT_UPDATE_INFORMATION_SUCCESS"
export const COMPLAINANT_UPDATE_INFORMATION_FAILED = "COMPLAINANT_UPDATE_INFORMATION_FAILED"

export const COMPLAINANT_UPLOAD_DOC_SEC = "COMPLAINANT_UPLOAD_DOC_SEC"
export const COMPLAINANT_UPLOAD_DOC_SEC_SUCCESS = "COMPLAINANT_UPLOAD_DOC_SEC_SUCCESS"
export const COMPLAINANT_UPLOAD_DOC_SEC_FAILED = "COMPLAINANT_UPLOAD_DOC_SEC_FAILED"

export const COMPLAINT_STATES = "COMPLAINT_STATES"
export const COMPLAINT_STATES_SUCCESS = "COMPLAINT_STATES_SUCCESS"
export const COMPLAINT_STATES_FAILED = "COMPLAINT_STATES_FAILED"

export const COMPLAINT_GET_ALL_INSA = "COMPLAINT_GET_ALL_INSA"
export const COMPLAINT_GET_ALL_INSA_SUCCESS = "COMPLAINT_GET_ALL_INSA_SUCCESS"
export const COMPLAINT_GET_ALL_INSA_FAILED = "COMPLAINT_GET_ALL_INSA_FAILED"

export const COMPLAINANT_GET_POLICY_FOR_ESCALATION = "COMPLAINANT_GET_POLICY_FOR_ESCALATION"
export const COMPLAINANT_GET_POLICY_FOR_ESCALATION_SUCCESS = "COMPLAINANT_GET_POLICY_FOR_ESCALATION_SUCCESS"
export const COMPLAINANT_GET_POLICY_FOR_ESCALATION_FAILD = "COMPLAINANT_GET_POLICY_FOR_ESCALATION_FAILD"

export const COMPLAINT_DOC_UPLOAD_GET_API = "COMPLAINT_DOC_UPLOAD_GET_API"
export const COMPLAINT_DOC_UPLOAD_GET_API_SUCCESS = "COMPLAINT_DOC_UPLOAD_GET_API_SUCCESS"
export const COMPLAINT_DOC_UPLOAD_GET_API_FAILED = "COMPLAINT_DOC_UPLOAD_GET_API_FAILED"

export const COMPLAINT_UPLOAD_DOC = "COMPLAINT_UPLOAD_DOC"
export const COMPLAINT_UPLOAD_DOC_SUCCESS = "COMPLAINT_UPLOAD_DOC_SUCCESS"
export const COMPLAINT_UPLOAD_DOC_FAILED = "COMPLAINT_UPLOAD_DOC_FAILED"


export const COMPLAINT_UPLOAD_MUL_USER_DATA = "COMPLAINT_UPLOAD_MUL_USER_DATA"
export const COMPLAINT_UPLOAD_MUL_USER_DATA_SUCCESS = "COMPLAINT_UPLOAD_MUL_USER_DATA_SUCCESS"
export const COMPLAINT_UPLOAD_MUL_USER_DATA_FAILED = "COMPLAINT_UPLOAD_MUL_USER_DATA_FAILED"

export const COMPLAINT_GET_LEAD = "COMPLAINT_GET_LEAD"
export const COMPLAINT_GET_LEAD_SUCCESS = "COMPLAINT_GET_LEAD_SUCCESS"
export const COMPLAINT_GET_LEAD_FAILED = "COMPLAINT_GET_LEAD_FAILED"

export const COMPLAINT_DELETE_STATUS = "COMPLAINT_DELETE_STATUS"
export const COMPLAINT_DELETE_STATUS_SUCCESS = "COMPLAINT_DELETE_STATUS_SUCCESS"
export const COMPLAINT_DELETE_STATUS_FAILED = "COMPLAINT_DELETE_STATUS_FAILED"

export const COMPLAINT_SERVETEL_CALL_LOG_EMPTY = "COMPLAINT_SERVETEL_CALL_LOG_EMPTY"

export const COMPLAINT_GET_DOC_FOR_APPROVAL = "COMPLAINT_GET_DOC_FOR_APPROVAL"
export const COMPLAINT_GET_DOC_FOR_APPROVAL_SUCCESS = "COMPLAINT_GET_DOC_FOR_APPROVAL_SUCCESS"
export const COMPLAINT_GET_DOC_FOR_APPROVAL_FAILED = "COMPLAINT_GET_DOC_FOR_APPROVAL_FAILED"

export const COMPLAINT_GET_DRAFT_FOR_DRAFT_APPROVAL = "COMPLAINT_GET_DRAFT_FOR_DRAFT_APPROVAL"
export const COMPLAINT_GET_DRAFT_FOR_DRAFT_APPROVAL_SUCCESS = "COMPLAINT_GET_DRAFT_FOR_DRAFT_APPROVAL_SUCCESS"
export const COMPLAINT_GET_DRAFT_FOR_DRAFT_APPROVAL_FAILED = "COMPLAINT_GET_DRAFT_FOR_DRAFT_APPROVAL_FAILED"

export const COMPLAINT_SAVE_DRAFT_EXPERT_APPROVE = "COMPLAINT_SAVE_DRAFT_EXPERT_APPROVE"
export const COMPLAINT_SAVE_DRAFT_EXPERT_APPROVE_SUCCESS = "COMPLAINT_SAVE_DRAFT_EXPERT_APPROVE_SUCCESS"
export const COMPLAINT_SAVE_DRAFT_EXPERT_APPROVE_FAILED = "COMPLAINT_SAVE_DRAFT_EXPERT_APPROVE_FAILED"

export const COMPLAINT_EXPERT_APPROVE_DRAFT = "COMPLAINT_EXPERT_APPROVE_DRAFT"
export const COMPLAINT_EXPERT_APPROVE_DRAFT_SUCCESS = "COMPLAINT_EXPERT_APPROVE_DRAFT_SUCCESS"
export const COMPLAINT_EXPERT_APPROVE_DRAFT_FAILED = "COMPLAINT_EXPERT_APPROVE_DRAFT_FAILED"

export const COMPLAINT_GET_ESCALATION_FOR_ESCALATION_APPROVE = "COMPLAINT_GET_ESCALATION_FOR_ESCALATION_APPROVE"
export const COMPLAINT_GET_ESCALATION_FOR_ESCALATION_APPROVE_SUCCESS = "COMPLAINT_GET_ESCALATION_FOR_ESCALATION_APPROVE_SUCCESS"
export const COMPLAINT_GET_ESCALATION_FOR_ESCALATION_APPROVE_FAILED = "COMPLAINT_GET_ESCALATION_FOR_ESCALATION_APPROVE_FAILED"

export const COMPLAINT_UPDATE_ESCALATION_APPROVE_MAIL = "COMPLAINT_UPDATE_ESCALATION_APPROVE_MAIL"
export const COMPLAINT_UPDATE_ESCALATION_APPROVE_MAIL_SUCCESS = "COMPLAINT_UPDATE_ESCALATION_APPROVE_MAIL_SUCCESS"
export const COMPLAINT_UPDATE_ESCALATION_APPROVE_MAIL_FAILED = "COMPLAINT_UPDATE_ESCALATION_APPROVE_MAIL_FAILED"

export const COMPLAINT_APPROVE_ESCALATION_MAIL = "COMPLAINT_APPROVE_ESCALATION_MAIL"
export const COMPLAINT_APPROVE_ESCALATION_MAIL_SUCCESS = "COMPLAINT_APPROVE_ESCALATION_MAIL_SUCCESS"
export const COMPLAINT_APPROVE_ESCALATION_MAIL_FAILED = "COMPLAINT_APPROVE_ESCALATION_MAIL_FAILED"

export const ADD_LEGAL_DRAFT_COMMENT = "ADD_LEGAL_DRAFT_COMMENT"
export const ADD_LEGAL_DRAFT_COMMENT_SUCCESS = "ADD_LEGAL_DRAFT_COMMENT_SUCCESS"
export const ADD_LEGAL_DRAFT_COMMENT_FAILED = "ADD_LEGAL_DRAFT_COMMENT_FAILED"