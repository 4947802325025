import { NotificationManager } from "components/common/react-notifications";
import { toast } from "helpers/Utils";
import {
  COMPLAINANT_GET_POLICY_FOR_ESCALATION,
  COMPLAINANT_GET_POLICY_FOR_ESCALATION_FAILD,
  COMPLAINANT_GET_POLICY_FOR_ESCALATION_SUCCESS,
  COMPLAINANT_GET_STATUS_BUCKET,
  COMPLAINANT_GET_STATUS_BUCKET_FAILED,
  COMPLAINANT_GET_STATUS_BUCKET_SUCCESS,
  COMPLAINANT_GET_USER_BASED_DATA,
  COMPLAINANT_GET_USER_BASED_DATA_FAILED,
  COMPLAINANT_GET_USER_BASED_DATA_SUCCESS,
  COMPLAINANT_UPDATE_COMPLAINT_STATUS,
  COMPLAINANT_UPDATE_COMPLAINT_STATUS_FAILED,
  COMPLAINANT_UPDATE_COMPLAINT_STATUS_SUCCESS,
  COMPLAINANT_UPDATE_INFORMATION,
  COMPLAINANT_UPDATE_INFORMATION_FAILED,
  COMPLAINANT_UPDATE_INFORMATION_SUCCESS,
  COMPLAINANT_USER_DETAIL_HANDLE_COMPLAINT,
  COMPLAINANT_USER_DETAIL_HANDLE_COMPLAINT_FAILED,
  COMPLAINANT_USER_DETAIL_HANDLE_COMPLAINT_SUCCESS,
  COMPLAINT_ADD_COMMENT,
  COMPLAINT_ADD_COMMENT_FAILED,
  COMPLAINT_ADD_COMMENT_SUCCESS,
  COMPLAINT_CALL_LOG,
  COMPLAINT_SERVETEL_CALL_LOG,
  COMPLAINT_CALL_LOG_FAILED,
  COMPLAINT_CALL_LOG_SUCCESS,
  COMPLAINT_CLAIM_OPERATION,
  COMPLAINT_CLAIM_OPERATION_FAILED,
  COMPLAINT_CLAIM_OPERATION_SUCCESS,
  COMPLAINT_DELETE_STATUS,
  COMPLAINT_DELETE_STATUS_FAILED,
  COMPLAINT_DELETE_STATUS_SUCCESS,
  COMPLAINT_DOC_UPLOAD_GET_API,
  COMPLAINT_DOC_UPLOAD_GET_API_FAILED,
  COMPLAINT_DOC_UPLOAD_GET_API_SUCCESS,
  COMPLAINT_FEE_OPERATION,
  COMPLAINT_FEE_OPERATION_FAILED,
  COMPLAINT_FEE_OPERATION_SUCCESS,
  COMPLAINT_FINAL_PAYMENT,
  COMPLAINT_FINAL_PAYMENT_FAILED,
  COMPLAINT_FINAL_PAYMENT_SUCCESS,
  COMPLAINT_GET_ALL_INSA,
  COMPLAINT_GET_ALL_INSA_FAILED,
  COMPLAINT_GET_ALL_INSA_SUCCESS,
  COMPLAINT_GET_COMMENTS,
  COMPLAINT_GET_COMMENTS_FAILED,
  COMPLAINT_GET_COMMENTS_SUCCESS,
  COMPLAINT_GET_LEAD,
  COMPLAINT_GET_LEAD_FAILED,
  COMPLAINT_GET_LEAD_SUCCESS,
  COMPLAINT_GET_WAIVEOFF,
  COMPLAINT_GET_WAIVEOFF_FAILED,
  COMPLAINT_GET_WAIVEOFF_SUCCESS,
  COMPLAINT_SECOND_ADD_FINAL_AMOUNT,
  COMPLAINT_SECOND_ADD_FINAL_AMOUNT_FAILED,
  COMPLAINT_SECOND_ADD_FINAL_AMOUNT_SUCCESS,
  COMPLAINT_STATES,
  COMPLAINT_STATES_FAILED,
  COMPLAINT_STATES_SUCCESS,
  COMPLAINT_UPDATE_COMMENT,
  COMPLAINT_UPDATE_COMMENT_FAILED,
  COMPLAINT_UPDATE_COMMENT_SUCCESS,
  COMPLAINT_UPLOAD_DOC,
  COMPLAINT_UPLOAD_DOC_FAILED,
  COMPLAINT_UPLOAD_DOC_SUCCESS,
  COMPLAINT_UPLOAD_MUL_USER_DATA,
  COMPLAINT_UPLOAD_MUL_USER_DATA_FAILED,
  COMPLAINT_UPLOAD_MUL_USER_DATA_SUCCESS,
  COMPLAINT_SERVETEL_CALL_LOG_EMPTY,
  COMPLAINT_GET_DOC_FOR_APPROVAL,
  COMPLAINT_GET_DOC_FOR_APPROVAL_SUCCESS,
  COMPLAINT_GET_DOC_FOR_APPROVAL_FAILED,
  COMPLAINT_GET_DRAFT_FOR_DRAFT_APPROVAL,
  COMPLAINT_GET_DRAFT_FOR_DRAFT_APPROVAL_SUCCESS,
  COMPLAINT_GET_DRAFT_FOR_DRAFT_APPROVAL_FAILED,
  COMPLAINT_SAVE_DRAFT_EXPERT_APPROVE,
  COMPLAINT_SAVE_DRAFT_EXPERT_APPROVE_SUCCESS,
  COMPLAINT_SAVE_DRAFT_EXPERT_APPROVE_FAILED,
  COMPLAINT_EXPERT_APPROVE_DRAFT,
  COMPLAINT_EXPERT_APPROVE_DRAFT_SUCCESS,
  COMPLAINT_EXPERT_APPROVE_DRAFT_FAILED,
  COMPLAINT_GET_ESCALATION_FOR_ESCALATION_APPROVE,
  COMPLAINT_GET_ESCALATION_FOR_ESCALATION_APPROVE_SUCCESS,
  COMPLAINT_GET_ESCALATION_FOR_ESCALATION_APPROVE_FAILED,
  COMPLAINT_UPDATE_ESCALATION_APPROVE_MAIL,
  COMPLAINT_UPDATE_ESCALATION_APPROVE_MAIL_SUCCESS,
  COMPLAINT_UPDATE_ESCALATION_APPROVE_MAIL_FAILED,
  COMPLAINT_APPROVE_ESCALATION_MAIL,
  COMPLAINT_APPROVE_ESCALATION_MAIL_SUCCESS,
  COMPLAINT_APPROVE_ESCALATION_MAIL_FAILED,
  ADD_LEGAL_DRAFT_COMMENT,
  ADD_LEGAL_DRAFT_COMMENT_SUCCESS,
  ADD_LEGAL_DRAFT_COMMENT_FAILED
} from "./Action";

const initialStatte = {
  waiveOfUsers: [],
  message: "",
  callLog: [],
  comments: [],
  userBasedData: [],
  statusBucket:[],
  optionBasedData:[],
  states:[],
  allInsa:[],
  forEscalation:[],
  docUploadGet:[],
  leadData:[],
  docUploaded:false,
  draftComplaintData:{},
  draftDataForApproval: {},
  complaitForEscaltionApprove : {},
  feePayLoading : false,
  commentLoading: false,
  successExpertApprove: false
};

export default function (state = initialStatte, action) {
  let data
  let mailingFollowTemp = []
  let ombFollowTemp = []
  let finalObj = {}
  let temp;
  let temp2 = []
  let userPolicies = [];
  let tempDoc = [];

  switch (action.type) {
    case COMPLAINT_GET_WAIVEOFF:
      return { ...state };

    case COMPLAINT_GET_WAIVEOFF_SUCCESS:
      data = action.data?.map((res) => {
        return { value: res._id, label: `${res.policyNumber}-${res.name}` };
      });
      return { ...state, waiveOfUsers: data };

    case COMPLAINT_GET_WAIVEOFF_FAILED:
      return { ...state, message: action.message };

    case COMPLAINT_FEE_OPERATION:
      return { ...state, feePayLoading : true };

    case COMPLAINT_FEE_OPERATION_SUCCESS:
      if (action.data?.success && action.data?.message != "User must have to signed contract first" && action.data?.message != "You can't go to Accept from the Legal Level" && action?.data?.message != "You can't go to Accept from the Ombudsaman Level" && action?.data?.message != "You can't go to Accept from the Company/IGMS") {
        NotificationManager.success(
          "Success !",
          action.data?.message,
          3000,
          null,
          null,
          "filled"
        )
      } else {
        NotificationManager.error(
          "Failed, Please try again !",
          action.data?.message,
          3000,
          null,
          null,
          "filled"
        )
      }
      return { ...state, message: action.data?.message, feePayLoading : false };

    case COMPLAINT_FEE_OPERATION_FAILED:
      return { ...state, message: action.message, feePayLoading : false };

    case COMPLAINT_SECOND_ADD_FINAL_AMOUNT:
      return { ...state };

    case COMPLAINT_SECOND_ADD_FINAL_AMOUNT_SUCCESS:
      if (action.data?.success) {
        NotificationManager.success(
          "Success !",
          action.data?.message,
          3000,
          null,
          null,
          "filled"
        )
      } else {
        NotificationManager.error(
          "Failed, Please try again !",
          action.data?.message,
          3000,
          null,
          null,
          "filled"
        )
      }
      return { ...state, message: action.data?.message };

    case COMPLAINT_SECOND_ADD_FINAL_AMOUNT_FAILED:
      return { ...state, message: action.message };

    case COMPLAINT_FINAL_PAYMENT:
      return { ...state };

    case COMPLAINT_FINAL_PAYMENT_SUCCESS:
      if (action.data?.success) {
        NotificationManager.success(
          "",
          action.data?.message,
          3000,
          null,
          null,
          "filled"
        )
      } else {
        NotificationManager.error(
          "Failed, Please try again !",
          action.data?.message,
          3000,
          null,
          null,
          "filled"
        )
      }
      return { ...state, message: action.data?.message };

    case COMPLAINT_FINAL_PAYMENT_FAILED:
      return { ...state, message: action.message };

    case COMPLAINT_CLAIM_OPERATION:
      return { ...state };

    case COMPLAINT_CLAIM_OPERATION_SUCCESS:
      if (action.data?.success) {
        NotificationManager.success(
          "",
          action.data?.message,
          3000,
          null,
          null,
          "filled"
        )
      } else {
        NotificationManager.error(
          "Failed, Please try again !",
          action.data?.message,
          3000,
          null,
          null,
          "filled"
        )
      }
      return { ...state, message: action.data?.message };

    case COMPLAINT_CLAIM_OPERATION_FAILED:
      return { ...state, message: action.message };

    case COMPLAINT_CALL_LOG:
      return { ...state };

    case COMPLAINT_SERVETEL_CALL_LOG:
      return { ...state };

    case COMPLAINT_SERVETEL_CALL_LOG_EMPTY:
      return {...state, callLog : {} }

    case COMPLAINT_CALL_LOG_SUCCESS:
      NotificationManager.success(
        "Success !",
        "Call log fetched Successfully !",
        3000,
        null,
        null,
        "filled"
      )
      return { ...state, callLog: {data : action?.recordingData?.data} };

    case COMPLAINT_CALL_LOG_FAILED:
      NotificationManager.error(
        "No Data !",
        "Call logs not available !",
        3000,
        null,
        null,
        "filled"
      )
      return { ...state, message: action.message };

    case COMPLAINT_ADD_COMMENT:
      return { ...state };

    case COMPLAINT_ADD_COMMENT_SUCCESS:
      return { ...state, message: action.message };

    case COMPLAINT_ADD_COMMENT_FAILED:
      return { ...state, message: action.message };

    case COMPLAINT_UPDATE_COMMENT:
      return { ...state };

    case COMPLAINT_UPDATE_COMMENT_SUCCESS:
      return { ...state, message: action.message };

    case COMPLAINT_UPDATE_COMMENT_FAILED:
      return { ...state, message: action.message };

    case COMPLAINT_GET_COMMENTS:
      return { ...state, commentLoading:true };

    case COMPLAINT_GET_COMMENTS_SUCCESS:
      // console.log("-------------------", action.data);
      mailingFollowTemp = action.data[0]?.mailingFollow?.mailingFollow?.length ? action.data[0]?.mailingFollow?.mailingFollow?.map(res => {return {com_by: res.followUpDoneBy, com_date: res.mailingLastDate, com_dis : res.mailingComment}}) : [];
      ombFollowTemp = action.data[0]?.ombudsmanFollow?.omdFollow?.length ? action.data[0]?.ombudsmanFollow?.omdFollow?.map(res => {return {com_by: res.followUpDoneBy, com_date: res.omdFollowDate, com_dis : res.omdFollowComment}}) : [];
      // finalObj = { ...action.data[0], communication: [...action.data[0]?.communication, ...mailingFollowTemp, ...ombFollowTemp] };
      finalObj = {
        ...(action.data[0] || {}),
        communication: [
          ...(action.data[0]?.communication || []),
          ...mailingFollowTemp,
          ...ombFollowTemp
        ]
      };
      
      finalObj.communication?.sort((a, b) => new Date(b.com_date) - new Date(a.com_date))
      return { ...state, comments: [finalObj] ,commentLoading:false};

    case COMPLAINT_GET_COMMENTS_FAILED:
      return { ...state, message: action.message ,commentLoading:false};

    case COMPLAINANT_GET_USER_BASED_DATA:
      return { ...state };

    case COMPLAINANT_GET_USER_BASED_DATA_SUCCESS:
      return { ...state, userBasedData: action.data };

    case COMPLAINANT_GET_USER_BASED_DATA_FAILED:
      return { ...state, message: action.message };

    case COMPLAINANT_GET_STATUS_BUCKET:
      return {...state}

    case COMPLAINANT_GET_STATUS_BUCKET_SUCCESS:
      temp = Object.values(Object.fromEntries(action.data?.statusBuckets))
      temp.map(res => {temp2 = [...temp2, ...res]})
      // console.log(temp2)
      return {...state,statusBucket:temp2}

    case COMPLAINANT_GET_STATUS_BUCKET_FAILED:
      return {...state,message:action.message}

    case COMPLAINANT_UPDATE_COMPLAINT_STATUS:
      return {...state}

    case COMPLAINANT_UPDATE_COMPLAINT_STATUS_SUCCESS:
      return {...state, message:action.message}

    case COMPLAINANT_UPDATE_COMPLAINT_STATUS_FAILED:
      return {...state,message:action.message}
 
    case COMPLAINANT_USER_DETAIL_HANDLE_COMPLAINT:
      return {...state}

    case COMPLAINANT_USER_DETAIL_HANDLE_COMPLAINT_SUCCESS:
      if (action.data?.length) {
        userPolicies = [
          { label: "Select All", value: "Select All" },
          ...(action.data?.map((res) => ({
            label: `${res.policyNumber} - ${res.userId?.name || 'Unknown'} - ${res.insuranceCompanyId?.name || 'Unknown'}`,
            value: res._id,
            policyNumber: res.policyNumber
          })) || [])
        ];  
      }
      return {...state, optionBasedData:userPolicies}

    case COMPLAINANT_USER_DETAIL_HANDLE_COMPLAINT_FAILED:
      return {...state, message:action.message}

    case COMPLAINANT_UPDATE_INFORMATION:
      return {...state}

    case COMPLAINANT_UPDATE_INFORMATION_SUCCESS:
      toast(action.message , "" , "success")
      return {...state, message:action.message}

    case COMPLAINANT_UPDATE_INFORMATION_FAILED:
      toast(action.message, "" , "error")
      return {...state, message:action.message}

      case COMPLAINT_STATES:
          return {...state}

      case COMPLAINT_STATES_SUCCESS:
          return {...state, states : action.data}

      case COMPLAINT_STATES_FAILED:
          return {...state, message:action.state}

      case COMPLAINT_GET_ALL_INSA:
        return {...state}

      case COMPLAINT_GET_ALL_INSA_SUCCESS:
        return {...state, allInsa:action.data}

      case COMPLAINT_GET_ALL_INSA_FAILED:
        return {...state, message:action.message}

      case COMPLAINANT_GET_POLICY_FOR_ESCALATION:
        return {...state}

      case COMPLAINANT_GET_POLICY_FOR_ESCALATION_SUCCESS:
        return {...state, forEscalation:action.data}

      case COMPLAINANT_GET_POLICY_FOR_ESCALATION_FAILD:
        return {...state, message:action.message}

      case COMPLAINT_DOC_UPLOAD_GET_API:
        return {...state, docUploaded:action.state?.name}

      case COMPLAINT_DOC_UPLOAD_GET_API_SUCCESS:
        toast("Document Uploaded !", "", "success")
        return {...state, docUploadGet:action.data, docUploaded:false}

      case COMPLAINT_DOC_UPLOAD_GET_API_FAILED:
        toast("Failed to Upload !", " ", "error")
        return {...state, message:action.message, docUploaded:false}

      case COMPLAINT_UPLOAD_DOC:
        return {...state}

      case COMPLAINT_UPLOAD_DOC_SUCCESS:
        return {...state, message:action.message}


      case COMPLAINT_UPLOAD_DOC_FAILED:
        return {...state, message:action.message}

      case COMPLAINT_UPLOAD_MUL_USER_DATA:
        return {...state}

      case COMPLAINT_UPLOAD_MUL_USER_DATA_SUCCESS:
        toast(action.message, "", "success")
        return {...state,message:action.message}

      case COMPLAINT_UPLOAD_MUL_USER_DATA_FAILED:
        return {...state, message:action.message}

      case COMPLAINT_GET_LEAD:
        return {...state}

      case COMPLAINT_GET_LEAD_SUCCESS:
        return {...state, leadData:action.data}

      case COMPLAINT_GET_LEAD_FAILED:
        return {...state, message:action.message}
        
      case COMPLAINT_DELETE_STATUS:
        return {...state}

      case COMPLAINT_DELETE_STATUS_SUCCESS:
        toast(action.message, "", "success")
        return {...state, message:action.message}

      case COMPLAINT_DELETE_STATUS_FAILED:
        return {...state, message:action.message}

      case COMPLAINT_GET_DOC_FOR_APPROVAL:
        return {...state}

      case COMPLAINT_GET_DOC_FOR_APPROVAL_SUCCESS:
        if (action.data?.leadId.doc) {
          tempDoc = action.data?.leadId.doc?.map(res => {return {label : res, value : res}})
        }
        return {...state, draftComplaintData: {...action.data, leadDocs : tempDoc}}

      case COMPLAINT_GET_DOC_FOR_APPROVAL_FAILED:
        return {...state, message: action.message}

      case COMPLAINT_GET_DRAFT_FOR_DRAFT_APPROVAL:
        return {...state}

      case COMPLAINT_GET_DRAFT_FOR_DRAFT_APPROVAL_SUCCESS:
        return {...state, draftDataForApproval : action.data}

      case COMPLAINT_GET_DRAFT_FOR_DRAFT_APPROVAL_FAILED:
        return {...state, message : action.message}

      case COMPLAINT_SAVE_DRAFT_EXPERT_APPROVE:
        return {...state}

      case COMPLAINT_SAVE_DRAFT_EXPERT_APPROVE_SUCCESS:
        toast("Draft saved successfully !", "", "success");
        return {...state, message :action.message}

      case COMPLAINT_SAVE_DRAFT_EXPERT_APPROVE_FAILED:
        toast("Failed to save !", "", "error");
        return {...state, message: "Failed to save draft !"}
      
      case COMPLAINT_EXPERT_APPROVE_DRAFT:
        return {...state}

      case COMPLAINT_EXPERT_APPROVE_DRAFT_SUCCESS:
        toast("Draft Mail Approved !", "", "success");
        return {...state, message : action.message, successExpertApprove: true}

      case COMPLAINT_EXPERT_APPROVE_DRAFT_FAILED:
        toast("Failed to Approve Draft Mail !", "", "error");
        return {...state, message : action.message, successExpertApprove: false}

      case COMPLAINT_GET_ESCALATION_FOR_ESCALATION_APPROVE:
        return {...state}

      case COMPLAINT_GET_ESCALATION_FOR_ESCALATION_APPROVE_SUCCESS:
        return {...state , complaitForEscaltionApprove : action.data}

      case COMPLAINT_GET_ESCALATION_FOR_ESCALATION_APPROVE_FAILED:
        return {...state, message : action.message}

      case COMPLAINT_UPDATE_ESCALATION_APPROVE_MAIL:
        return {...state}

      case COMPLAINT_UPDATE_ESCALATION_APPROVE_MAIL_SUCCESS:
        toast("Draft saved successfully !", "", "success");
        return {...state, message : action.message}

      case COMPLAINT_UPDATE_ESCALATION_APPROVE_MAIL_FAILED:
        toast("Failed to save draft !", "", "error");
        return {...state, message : action.message}
      
      case COMPLAINT_APPROVE_ESCALATION_MAIL:
        return {...state}

      case COMPLAINT_APPROVE_ESCALATION_MAIL_SUCCESS:
        toast("Draft Mail Approved !", "", "success");
        return {...state, message : action.message, successExpertApprove: true}

      case COMPLAINT_APPROVE_ESCALATION_MAIL_FAILED:
        toast("Failed to Approve !", "", "error");
        return {...state, message : action.message, successExpertApprove: false}

      case ADD_LEGAL_DRAFT_COMMENT:
        return {...state}

      case ADD_LEGAL_DRAFT_COMMENT_SUCCESS:
        toast("Comment added successfully !", "", "success");
        return {...state, message : action.message}

      case ADD_LEGAL_DRAFT_COMMENT_FAILED:
        toast("Failed to add comment !", "", "error");
        return {...state, message : action.message}

        default:
      return { ...state };
  }
}
